import { Lightning, Router } from '@lightningjs/sdk';
import SeenspireApp from './SeenspireApp';
import Qr from './Qr';
import Splash from './Splash';
// import initFireboltSdk from './firebolt';

export default class App extends Lightning.Component {
  _init() {
    // initFireboltSdk(this);
    console.log('[VERSION]: 1.0.8')
  }
  _setup() {
    Router.startRouter({
      root: '',
      routes: [
        {
          path: 'qr',
          component: Qr,
        },
        {
          path: 'crypto/:screenId',
          component: SeenspireApp,
        },
        {
          path: '',
          component: Splash
        },
      ],
    });
  }
}
